import React from "react";
import c from "./Footer.module.scss";
import footerLogo from "../../../assets/vector.svg";
import footerElectricContractor from "../../../assets/Electric_Contractor.svg";
import footerElectricSafety from "../../../assets/footerlogo.png";
import map from "../../../assets/map.svg";
import phone from "../../../assets/phone.svg";
import watsup from "../../../assets/whatsapp.svg";
import email from "../../../assets/mail.svg";
import { Container } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}>
        <Container sx={{ backgroundColor: "#16171A" }}>
          <div className={c.container}>
            <div className={c.top}>
              <div className={c.head}>
                <div className={c.hed_left}>
                  <div className={c.logo}>
                    <Link to="/">
                      <img src={footerLogo} alt="footer-logo" />
                    </Link>
                  </div>
                  <div className={c.text}>
                    <p>
                      Zak Electric is a neighborhood, family-possessed
                      organization, zeroed in on giving the best electrical
                      technician administrations. We highly esteem the quality
                      and security of the work we do in your home or business.
                    </p>
                    <p>ESA 7015394</p>
                  </div>
                </div>

                <div className={c.hed_right}>
                  <div className={c.list}>
                    <ul>
                      <li>Quick Access</li>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/portfolio">Portfolio</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact us</Link>
                      </li>
                    </ul>
                  </div>
                  <div className={c.list}>
                    <ul>
                      <li>Services</li>
                      <li>
                        <Link to="/services/residential">Residential</Link>
                      </li>
                      <li>
                        <Link to="/services/commercial">Commercial</Link>
                      </li>
                      <li>
                        <Link to="/services/industrial">Industrial</Link>
                      </li>
                    </ul>
                  </div>
                  <div className={c.list}>
                    <ul>
                      <li>Contact Us</li>
                      <li>
                        <p>
                          <img src={map} width={24} height={24} alt="" />
                          Address
                        </p>
                        <a
                          href="https://maps.app.goo.gl/568YFbQ9tk6buGjV6"
                          target="_blank"
                          rel="noopener noreferrer">
                          <p>50 Ottawa St S. - Kitchener</p>
                        </a>
                      </li>
                      <li>
                        <p>
                          <img src={phone} width={24} height={24} alt="" />
                          Phone
                        </p>
                        <a href="tel:+2262223000">
                          <p>226-222-3000</p>
                        </a>
                      </li>
                      <li>
                        <p>
                          <img src={watsup} width={24} height={24} alt="" />
                          WhatsApp
                        </p>
                        <a href="tel:+2262223000">
                          <p>226-222-3000</p>
                        </a>
                      </li>
                      <li>
                        <p>
                          <img src={email} width={24} height={24} alt="" />
                          E-mail
                        </p>
                        <a href="mailto:admin@zakelectric.ca">
                          {" "}
                          <p>admin@zakelectric.ca</p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className={c.foot}>
                <img
                  src={footerElectricContractor}
                  width={135}
                  height={68}
                  alt=""
                />
                <img
                  src={footerElectricSafety}
                  width={128}
                  height={68}
                  alt=""
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}>
        <Container sx={{ backgroundColor: "#EC1C23" }}>
          <div className={c.bottom}>
            <div className={c.left}>
              <p>ZAK ELECTRIC © Copyright 2024. All Rights Reserved.</p>
            </div>
            <p className="middle">
              Designed & Developed by{" "}
              <a
                href="http://webelocity.io"
                target="_blank"
                rel="noopener noreferrer">
                Webelocity
              </a>
            </p>
            <div className={c.right}>
              <p>
                <Link to="/legal-disclaimer">Legal Disclaimer</Link>
              </p>
              <p>
                <Link to="/terms-conditions">Terms & Conditions</Link>
              </p>
              <p>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default Footer;
