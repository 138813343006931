import React from "react";
import s from "./OurServing.module.scss";
import List from "./List/List";
import back from "./../../../assets/AreasSection.svg";
import { Container } from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";

const OurServing = ({ handleOpenConsultation }) => {
  const matche = useMediaQuery("(min-width: 1166px)");
  const [showArrow, setShowArrow] = React.useState(false);

  return (
    <>
      <div className={s.container} style={{ backgroundImage: `url(${back})` }}>
        <Container>
          <motion.div
            initial={{ opacity: 0, x: matche ? 70 : 0 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ ease: "easeOut", duration: 0.4 }}
            className={s.box1}>
            <div className={s.textBox}>
              <p className={s.textRed}>Lighting Up Communities</p>
              <p className={s.title}> Our Serving Areas</p>
              <p className={s.text}>
                {" "}
                Zak Electric has you covered. Our team of licensed electricians
                is prepared and eager to assist you. For all your electrical
                needs, contact Zak Electric.
              </p>
            </div>
            {/* <List /> */}
            <div className={s.boxButton}>
              <Link
                className="light-red-button"
                to="/contact"
                onMouseLeave={() => setShowArrow(false)}
                onMouseOver={() => setShowArrow(true)}>
                Contact Us{" "}
                {showArrow && (
                  <motion.svg
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ ease: "easeOut", duration: 0.2 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none">
                    <path
                      d="M1 5H15M15 5L11 9M15 5L11 1"
                      stroke="#C60F15"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </motion.svg>
                )}
              </Link>
              <button className="red-button" onClick={handleOpenConsultation}>
                Get a Consultation
              </button>
            </div>
          </motion.div>
        </Container>
      </div>
    </>
  );
};

export default OurServing;
