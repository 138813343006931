import "./WhyChooseUs.scss";
import { Container } from "@mui/material";
import { motion, animate } from "framer-motion";

const WhyChooseUs = ({ handleOpenConsultation }) => {
  const reasons = [
    {
      name: "Licensed Electricians",
      text: " Our team comprises experienced, certified engineers, designers, and technicians who excel in various electrical service needs. Continuous safety-focused training keeps us updated on the latest technologies and procedures, ensuring meticulous attention to detail. When we complete a project, it's done right, to your utmost satisfaction.",
      svg: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M26 35V44L30 41L34 44V35M20 38H10C8.93913 38 7.92172 37.5786 7.17157 36.8284C6.42143 36.0783 6 35.0609 6 34V14C6 11.8 7.8 10 10 10H38C39.0609 10 40.0783 10.4214 40.8284 11.1716C41.5786 11.9217 42 12.9391 42 14V34C41.9993 34.7015 41.8141 35.3904 41.4631 35.9977C41.112 36.605 40.6075 37.1093 40 37.46M12 18H36M12 24H18M12 30H16M24 30C24 31.5913 24.6321 33.1174 25.7574 34.2426C26.8826 35.3679 28.4087 36 30 36C31.5913 36 33.1174 35.3679 34.2426 34.2426C35.3679 33.1174 36 31.5913 36 30C36 28.4087 35.3679 26.8826 34.2426 25.7574C33.1174 24.6321 31.5913 24 30 24C28.4087 24 26.8826 24.6321 25.7574 25.7574C24.6321 26.8826 24 28.4087 24 30Z"
            stroke="#EC1C23"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Upfront Pricing",
      text: " No surprises here. Our technicians thoroughly assess each project and provide a transparent, upfront cost estimate before work commences. We don't believe in hidden fees or hourly rates – just clear, professional assessments. Plus, our money-back guarantee ensures your job will be completed to your satisfaction.\n",
      svg: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M28 16H23C22.2044 16 21.4413 16.3161 20.8787 16.8787C20.3161 17.4413 20 18.2044 20 19C20 19.7956 20.3161 20.5587 20.8787 21.1213C21.4413 21.6839 22.2044 22 23 22H25C25.7956 22 26.5587 22.3161 27.1213 22.8787C27.6839 23.4413 28 24.2044 28 25C28 25.7956 27.6839 26.5587 27.1213 27.1213C26.5587 27.6839 25.7956 28 25 28H20M24 28V31M24 13V16M10 42V10C10 8.93913 10.4214 7.92172 11.1716 7.17157C11.9217 6.42143 12.9391 6 14 6H34C35.0609 6 36.0783 6.42143 36.8284 7.17157C37.5786 7.92172 38 8.93913 38 10V42L32 38L28 42L24 38L20 42L16 38L10 42Z"
            stroke="#EC1C23"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Friendly and Knowledgeable",
      text: " As a family-owned and operated business, we foster a friendly and knowledgeable workforce. From management to service technicians, we treat colleagues and customers like family. Continuous training remains a cornerstone of our commitment to excellence",
      svg: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M38.9996 25.1439L23.9996 39.9999L8.99962 25.1439C8.01024 24.1811 7.23091 23.0239 6.71072 21.7451C6.19053 20.4664 5.94075 19.0938 5.9771 17.7137C6.01345 16.3337 6.33515 14.9761 6.92194 13.7265C7.50873 12.4769 8.3479 11.3624 9.3866 10.453C10.4253 9.54368 11.641 8.85924 12.9572 8.44283C14.2735 8.02641 15.6616 7.88703 17.0344 8.03347C18.4071 8.1799 19.7346 8.60898 20.9334 9.29368C22.1321 9.97838 23.1761 10.9039 23.9996 12.0119C24.8267 10.9119 25.8719 9.99451 27.0698 9.31707C28.2678 8.63964 29.5926 8.21676 30.9615 8.07491C32.3304 7.93305 33.7138 8.07526 35.0252 8.49265C36.3366 8.91004 37.5477 9.59361 38.5827 10.5006C39.6178 11.4076 40.4545 12.5184 41.0405 13.7636C41.6265 15.0089 41.9491 16.3616 41.9882 17.7373C42.0274 19.1129 41.7821 20.4819 41.2679 21.7584C40.7536 23.0349 39.9814 24.1915 38.9996 25.1559M23.9997 12L17.4137 18.586C17.0388 18.9611 16.8281 19.4697 16.8281 20C16.8281 20.5303 17.0388 21.0389 17.4137 21.414L18.4997 22.5C19.8797 23.88 22.1197 23.88 23.4997 22.5L25.4997 20.5C26.6932 19.3065 28.3119 18.6361 29.9997 18.6361C31.6875 18.6361 33.3062 19.3065 34.4997 20.5L38.9997 25M25 31L29 35M30 26L34 30"
            stroke="#EC1C23"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ];
  return (
    <div className="extra-container-choose-us">
      <Container>
        <div className="why-choose-us-wrapper">
          <div className="header">
            <p>features</p>
            <h2>Why Choose Us</h2>
          </div>
          <div className="carts-wrapper">
            {reasons.map((reason, index) => (
              <div key={index} className="cart">
                <motion.div
                  initial={{ y: 100 }}
                  whileInView={{
                    scale: [1, 1.5, 1.5, 1],
                    y: 0,
                  }}
                  viewport={{ once: true, amount: 0.6 }}
                  transition={{ ease: "easeOut", duration: 0.8 }}
                  className="top-part-circle">
                  {reason.svg}
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ ease: "easeOut", duration: 0.4, delay: 0.7 }}
                  viewport={{ once: true, amount: 0.6 }}
                  className="bottom-part">
                  <h3>{reason.name}</h3>
                  <p> {reason.text}</p>
                </motion.div>
              </div>
            ))}
          </div>
          <button className="red-button" onClick={handleOpenConsultation}>
            Get a Consultation
          </button>
        </div>
      </Container>
    </div>
  );
};

export default WhyChooseUs;
