import emailjs from "@emailjs/browser";

export const emailjsSend = async (data, templateID) => {
  try {
    await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICEID,
      templateID,
      data,
      process.env.REACT_APP_EMAILJS_USERID
    );
    return true;
  } catch (err) {
    return false;
  }
};
