import React from 'react';
import './Rule.scss'

const Rule = (props) => {
    return (
        <div className='rule-wrapper'>
            {props.children}
        </div>
    );
};

export default Rule;