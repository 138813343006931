import React from "react";
import s from "./HeaderFirstPart.module.scss";
import youtube from "../../../../assets/uil_youtube.svg";
import fb from "../../../../assets/fb.svg";
import image_9 from "../../../../assets/image 9.svg";
import image_11 from "../../../../assets/image 11.svg";
import rect from "../../../../assets/Mask group.png";
import insta from "../../../../assets/instagram.svg";
import soc_icons from "../../../../assets/Social Icons.svg";
import { Container } from "@mui/material";

const HeaderFirstPart = () => {
  return (
    <Container sx={{ backgroundColor: "black" }}>
      <div className={s.container}>
        <p className={s.text}>
          <a href="mailto:admin@zakelectric.ca">admin@zakelectric.ca</a>
        </p>
        <div className={s.line}></div>
        <div className={s.imgBox}>
          <a
            href="https://www.instagram.com/ZAKElectric/"
            target="_blank"
            rel="noopener noreferrer">
            <img src={insta} className={s.img} alt="img" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100083099332438"
            target="_blank"
            rel="noopener noreferrer">
            <img src={fb} className={s.img} alt="img" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCWxtJwPHo6ZQQbI3navdVJA"
            target="_blank"
            rel="noopener noreferrer">
            <img src={youtube} className={s.img} alt="img" />
          </a>
          <a
            href="https://twitter.com/zakelectric0"
            target="_blank"
            rel="noopener noreferrer">
            <img src={soc_icons} className={s.img} alt="img" />
          </a>
          <a
            href="https://home-bart.homestars.com/companies/2977602-zak-electric"
            target="_blank"
            rel="noopener noreferrer">
            <img src={image_9} className={s.img} alt="img" />
          </a>
          <a
            href="https://g.page/r/CQrPn5ZpZ0o2EBM/review"
            target="_blank"
            rel="noopener noreferrer">
            <img src={rect} className={s.img} alt="img" />
          </a>
          <a
            href="https://www.yellowpages.ca/bus/Ontario/KITCHENER/ZAK-Electric/103004513.html?what=Emergency+Lighting+Equipment&where=Kitchener%2FWaterloo+Regional+Airport+Breslau+ON&useContext=true"
            target="_blank"
            rel="noopener noreferrer">
            <img src={image_11} className={s.img} alt="img" />
          </a>
        </div>
      </div>
    </Container>
  );
};

export default HeaderFirstPart;
