import { useState } from "react";
import "./ElevatingResidential.scss";
import { Container, useMediaQuery } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const ElevatingResidential = () => {
  const Smatche = useMediaQuery("(min-width: 1166px)");

  const [showArrow, setShowArrow] = useState(false);
  const boxes = [
    {
      svg: (
        <svg
          width="40"
          height="41"
          viewBox="0 0 40 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 35.5L9.16667 31.3333M30.8333 9.66667L35 5.5M16.6667 18.8333L13.3333 22.1667M21.6667 23.8333L18.3333 27.1667M11.6666 20.5L19.9999 28.8333L17.4999 31.3333C16.956 31.8952 16.3057 32.3432 15.587 32.6512C14.8682 32.9592 14.0954 33.1211 13.3134 33.1275C12.5315 33.1338 11.7561 32.9845 11.0324 32.6882C10.3087 32.3918 9.65129 31.9545 9.09835 31.4015C8.54541 30.8486 8.10804 30.1911 7.81173 29.4675C7.51541 28.7438 7.36608 27.9684 7.37243 27.1865C7.37878 26.4045 7.54068 25.6317 7.8487 24.9129C8.15673 24.1942 8.60471 23.5439 9.16656 23L11.6666 20.5ZM28.3333 20.5L20 12.1667L22.5 9.66666C23.0439 9.10482 23.6942 8.65683 24.4129 8.34881C25.1317 8.04078 25.9045 7.87888 26.6865 7.87253C27.4684 7.86618 28.2438 8.01551 28.9675 8.31183C29.6911 8.60814 30.3486 9.04551 30.9015 9.59845C31.4545 10.1514 31.8918 10.8088 32.1882 11.5325C32.4845 12.2562 32.6338 13.0316 32.6275 13.8135C32.6211 14.5955 32.4592 15.3683 32.1512 16.0871C31.8432 16.8058 31.3952 17.4561 30.8333 18L28.3333 20.5Z"
            stroke="#EC1C23"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      text: "Comprehensive electrical layout and design",
    },
    {
      svg: (
        <svg
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.66699 20.5H7.33366M20.667 5.5V7.16667M34.0003 20.5H35.667M10.0003 9.83333L11.167 11M31.3337 9.83333L30.167 11M16.834 28.8333H24.5007M15.667 27.1667C14.2678 26.1173 13.2342 24.6542 12.7127 22.9847C12.1912 21.3153 12.2082 19.524 12.7613 17.8648C13.3144 16.2055 14.3755 14.7623 15.7944 13.7396C17.2133 12.717 18.918 12.1667 20.667 12.1667C22.416 12.1667 24.1207 12.717 25.5396 13.7396C26.9585 14.7623 28.0196 16.2055 28.5727 17.8648C29.1258 19.524 29.1428 21.3153 28.6213 22.9847C28.0998 24.6542 27.0662 26.1173 25.667 27.1667C25.0163 27.8108 24.5263 28.5991 24.2368 29.4677C23.9472 30.3363 23.8662 31.2609 24.0003 32.1667C24.0003 33.0507 23.6491 33.8986 23.024 34.5237C22.3989 35.1488 21.551 35.5 20.667 35.5C19.7829 35.5 18.9351 35.1488 18.31 34.5237C17.6848 33.8986 17.3337 33.0507 17.3337 32.1667C17.4678 31.2609 17.3867 30.3363 17.0972 29.4677C16.8077 28.5991 16.3177 27.8108 15.667 27.1667Z"
            stroke="#EC1C23"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      text: "Illuminating lighting upgrades that not only brighten but truly shine",
    },
    {
      svg: (
        <svg
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g id="tabler-icon-circuit-ground">
            <path
              id="Vector"
              d="M20.3333 22.1666V8.83331M7 22.1666H33.6667M12 27.1666H28.6667M17 32.1666H23.6667"
              stroke="#EC1C23"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      text: "Wiring and installation/upgrades for a brighter future",
    },
  ];

  const matches = useMediaQuery("(max-width: 1320px)");
  return (
    <div className="extra-container">
      <Container>
        <div className="elevating-wrapper">
          <motion.div
            initial={{ opacity: 0, x: Smatche ? 80 : 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true, amount: 0.4 }}
            transition={{ ease: "easeOut", duration: 0.4 }}
            className="image">
            <img src={require("../../assets/house.webp")} alt="house" />
            {matches && (
              <Link
                to="/about"
                onMouseLeave={() => setShowArrow(false)}
                onMouseOver={() => setShowArrow(true)}
                className="light-red-button">
                Read More{" "}
                {showArrow && (
                  <motion.svg
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ ease: "easeOut", duration: 0.2 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none">
                    <path
                      d="M1 5H15M15 5L11 9M15 5L11 1"
                      stroke="#C60F15"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </motion.svg>
                )}
              </Link>
            )}
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true, amount: 0.4 }}
            transition={{ ease: "easeOut", duration: 0.4, delay: 0.4 }}
            className="right-side">
            <>
              <div className="header">
                <p>About Us</p>
                <h2>Elevating residential & commercial spaces</h2>
              </div>
              <p>
                At Zak Electric, we are a local, family-owned business dedicated
                to delivering top-tier electrical services.
              </p>
            </>
            {!matches && (
              <Link
                to="/about"
                onMouseLeave={() => setShowArrow(false)}
                onMouseOver={() => setShowArrow(true)}
                className="light-red-button">
                Read More{" "}
                {showArrow && (
                  <motion.svg
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ ease: "easeOut", duration: 0.2 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none">
                    <path
                      d="M1 5H15M15 5L11 9M15 5L11 1"
                      stroke="#C60F15"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </motion.svg>
                )}
              </Link>
            )}
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true, amount: 0.4 }}
            transition={{ ease: "easeOut", duration: 0.4, delay: 0.8 }}
            className="absolute-box">
            {boxes.map((box, index) => (
              <div key={index} className="inside-box">
                {box.svg}
                <p>{box.text}</p>
              </div>
            ))}
          </motion.div>
        </div>
      </Container>
    </div>
  );
};

export default ElevatingResidential;
