import React from 'react';
import './PrivacyPolicy.scss'
import BlackBanner from "../../components/BlackBanner";
import {Container} from "@mui/material";
import Rule from "../../components/Rule";

const PrivacyPolicy = () => {
    const data = {
        title: "Privacy Policy",
        description: "Your Privacy Matters to Us"
    }
    return (
        <div className={'privacy-policy-wrapper'}>
            <BlackBanner data={data}/>
            <Container>
                <div className='rules-wrapper'>
                    <div className='left-side-wrapper'>
                        <Rule>
                            <h1>LEGAL NOTICES AND TERMS OF USE</h1>
                            <p>This World Wide Web site is owned and operated by Zak Electric. This page contains
                                proprietary
                                rights notices and terms of use of this site. By using this site, you accept these terms
                                of
                                use.
                                If you do not agree to these terms of use, please do not use the site.</p>
                        </Rule>
                        <Rule>
                            <h1>LIMITS ON USE</h1>
                            <p>This Website is owned and operated by Zak Electric and is for the personal,
                                non-commercial use of the User. The User may not modify, transmit, display, perform,
                                reproduce, publish, license, create derivative works from, transfer, or sell, in any
                                way, information, documents, graphics, software, products, or services ('Materials”)
                                obtained from this Web site, except that the user may view content in its present form.
                                The user may download one (1) copy of the materials on any computer for personal,
                                non-commercial home use, provided the user keeps all copyright and other proprietary
                                notices intact. The use of any materials on any other Web site or networked computer
                                environment is prohibited. User recognizes that unauthorized use of Website content may
                                subject the user to civil or criminal liability.</p>
                        </Rule>
                        <Rule>
                            <h1>APPLICABLE LAWS</h1>
                            <p>This website (excluding linked sites) is controlled by Zak Electric from its offices
                                within the province of Ontario, Canada. By accessing this website, you and Zak Electric
                                agree that the statutes and laws of the province of Ontario, without regard to the
                                conflicts of laws and principles thereof, will apply to all matters relating to the use
                                of this Website. Those who choose to access this site from other locations do so on
                                their initiative and are responsible for compliance with local laws, if and to the
                                extent local laws are applicable.</p>
                        </Rule>
                        <Rule>
                            <h1>WARRANTY DISCLAIMER AND LIMITATION ON LIABILITY</h1>
                            <p>This site may include technical inaccuracies or typographical errors. Information on this
                                site and any products or services to which this site refers may be changed at any time
                                without notice. Zak Electric does not warrant that the functions in its Website
                                materials will be uninterrupted or error-free, that defects will be corrected, or that
                                this site or the server that makes it available will be free of viruses or other harmful
                                components. Zak Electric makes no representations whatsoever about any other Website you
                                may access through this one. Such linked sites are not under the control of Zak
                                Electric, and Zak Electric is not responsible for the contents of any linked site or any
                                link contained in a linked site or any changes to such sites. Such links are provided
                                only as a convenience, and the inclusion of a link does not imply Zak Electric’s
                                endorsement of the linked site. Links to this Web site should not be made without the
                                permission of Zak Electric. All such materials are provided “as is” without warranty of
                                any kind, either express or implied, to the fullest extent permissible pursuant to
                                applicable law, and Zak Electric hereby disclaims all warranties and conditions with
                                regard to the materials, including all implied warranties and conditions of
                                merchantability, fitness for a particular purpose, title, and non-infringement.</p>
                        </Rule>
                    </div>
                    <div className='right-side-wrapper'>
                        <Rule>
                            <h1>PRIVACY POLICY</h1>
                            <p>Zak Electric is committed to meeting all of our obligations to our customers to protect
                                their data and only to collect, use, and disclose it for authorized purposes. Zak
                                Electric may gather statistical and user preference data through cookies, profiling, and
                                other data-gathering techniques. Still, this process does not involve collecting, using,
                                or disclosing personally identifiable information. Zak Electric may go beyond acting
                                solely as a conduit to create enhanced data. Zak Electric may do this by collecting data
                                on URLs, user identity certificates, pair wire, temporary or site IDs, and other online
                                contact information. Zak Electric does not integrate such information with names,
                                addresses, e-mails, or other data so as to identify any person. Information obtained in
                                this manner by Zak Electric does not include personally identifiable information such as
                                name, address, telephone number, and e-mail, which are neither collected nor used by Zak
                                Electric in its creation of enhanced data nor disclosed by Zak Electric to others,
                                except with the consent of the individual to whom the data pertains. Through cookies,
                                Zak Electric may also collect, store, and use information regarding a user's computer
                                system and associate this information with other information collected from the user.
                                Zak Electric may use this information for completion and support of current activity,
                                Website and system administration, customization of websites of our business customers,
                                improvement of the content of the Website, research and development, or to notify the
                                user about updates to the Website. This information may be distributed to Zak Electric,
                                Zak Electric’s agents, and Zak Electric’s business customers. Any communications or
                                materials sent to the Website by any user via electronic mail or otherwise, including
                                any comments, data, questions, suggestions, or the like, will be treated as
                                non-confidential and non-proprietary. Any use of such material does not violate any
                                user’s rights, including moral rights, privacy rights, proprietary or other property
                                rights, publicity rights, rights to credit for material or ideas, or any other right to
                                approve the way Zak Electric uses such material.</p>
                        </Rule>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default PrivacyPolicy;