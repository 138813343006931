import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./Pages/Home/Home";
import WebFont from "webfontloader";
import React, { useEffect, useState, Suspense } from "react";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import LegalDisclaimer from "./Pages/LegalDisclaimer/LegalDisclaimer";
import ConsultationModal from "./components/ConsultationModal";
import { CircularProgress } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactGA from "./ga";

const ContactUsPage = React.lazy(() => import("./Pages/ContactUsPage"));
const AboutUsPage = React.lazy(() => import("./Pages/AboutUs/AboutUsPage"));
const Portfolio = React.lazy(() => import("./Pages/Portfolio/Portfolio"));
const Residential = React.lazy(() => import("./Pages/Services/Residential"));
const Commercial = React.lazy(() => import("./Pages/Services/Commercial"));
const Industrial = React.lazy(() => import("./Pages/Services/Industrial"));
const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["DM Sans", "Inter", "Open Sans"],
      },
    });
  }, []);
  const [openConsultation, setOpenConsultation] = useState(false);
  const handleOpenConsultation = () => {
    setOpenConsultation(true);
  };
  const handleCloseConsultation = () => {
    setOpenConsultation(false);
  };

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <ConsultationModal
          handleClose={handleCloseConsultation}
          open={openConsultation}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Layout handleOpenConsultation={handleOpenConsultation} />
            }>
            <Route
              index
              element={<Home handleOpenConsultation={handleOpenConsultation} />}
            />
            <Route
              path="/contact"
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <CircularProgress />
                    </div>
                  }>
                  <ContactUsPage
                    handleOpenConsultation={handleOpenConsultation}
                  />
                </Suspense>
              }
            />
            <Route
              path="/about"
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <CircularProgress />
                    </div>
                  }>
                  <AboutUsPage
                    handleOpenConsultation={handleOpenConsultation}
                  />
                </Suspense>
              }
            />
            <Route
              path="/portfolio"
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <CircularProgress />
                    </div>
                  }>
                  <Portfolio handleOpenConsultation={handleOpenConsultation} />{" "}
                </Suspense>
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsAndConditions />} />
            <Route path="/legal-disclaimer" element={<LegalDisclaimer />} />
            <Route path="/services">
              <Route
                path="/services/residential"
                element={
                  <Suspense
                    fallback={
                      <div
                        style={{
                          width: "100vw",
                          height: "100vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <CircularProgress />
                      </div>
                    }>
                    <Residential
                      handleOpenConsultation={handleOpenConsultation}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/services/commercial"
                element={
                  <Suspense
                    fallback={
                      <div
                        style={{
                          width: "100vw",
                          height: "100vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <CircularProgress />
                      </div>
                    }>
                    <Commercial
                      handleOpenConsultation={handleOpenConsultation}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/services/industrial"
                element={
                  <Suspense
                    fallback={
                      <div
                        style={{
                          width: "100vw",
                          height: "100vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <CircularProgress />
                      </div>
                    }>
                    <Industrial
                      handleOpenConsultation={handleOpenConsultation}
                    />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        </Routes>
      </div>
      {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
    </QueryClientProvider>
  );
}

export default App;
