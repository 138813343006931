import React from 'react'
import HeaderFirstPart from "./HeaderFirts/HeaderFirstPart";
import HeaderSecondPart from "./HeaderSecondPart/HeaderSecondPart";

const Header = ({handleOpenConsultation}) => {

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            zIndex: "10",
            position: "relative"
        }}>
            <HeaderFirstPart/>
            <HeaderSecondPart handleOpenConsultation={handleOpenConsultation}/>
        </div>
    )
}

export default Header
