import React from 'react';
import '../PrivacyPolicy/PrivacyPolicy.scss'
import BlackBanner from "../../components/BlackBanner";
import {Container} from "@mui/material";
import Rule from "../../components/Rule";

const TermsAndConditions = () => {
    const data = {
        title: 'General Terms and Conditions of Sale',
        description: "Understanding Our Terms and Commitments"
    }
    return (
        <div className={'privacy-policy-wrapper'}>
            <BlackBanner data={data}/>
            <Container>
                <div className='rules-wrapper'>
                    <div className='left-side-wrapper'>
                        <p className='other'>All sales of goods and services are subject to the attached terms and conditions.</p>
                        <Rule>
                            <h1>TITLE</h1>
                            <p>Title to the goods sold to the customer remains with Zak Electric (“Zak Electric”) until
                                paid in full (including all applicable sales, goods and services, and other taxes and
                                interest on overdue accounts), whether or not such goods have been affixed or otherwise
                                attached to any real or other property of the customer or any third party.</p>
                        </Rule>
                        <Rule>
                            <h1>PAYMENT</h1>
                            <p>Payment is due upon receipt of your invoice, as indicated by the invoice date unless
                                otherwise stated on the face of the invoice. Interest shall accrue on all overdue
                                accounts at 24% per annum, calculated and compounded monthly.</p>
                        </Rule>
                        <Rule>
                            <h1>FINAL SALE AND WARRANTY</h1>
                            <p>All sales are final. Zak Electric's only warranty shall be that the goods supplied shall
                                be of merchantable quality in accordance with the specifications attached. All work done
                                shall be in a good and workmanlike manner.
                                Zak Electric's liability under this warranty shall be restricted to replacing all goods
                                shown to be otherwise than as warranted and/or work which has not been done in a good
                                and workmanlike manner. Liability is limited to such replacement, and Zak Electric shall
                                not be liable whatsoever or howsoever caused. Delivery of goods and/or the provision of
                                services by Zak Electric shall be subject to delays caused by any force majeure, which
                                shall include but not be limited to national defence, civil commotion, strike, labour
                                trouble, fire, flood, accident, failure in production equipment, inability to obtain
                                material, or any other cause beyond the reasonable control of Zak Electric.</p>
                        </Rule>
                        <Rule>
                            <h1>TAXES</h1>
                            <p>All sales taxes, goods and services taxes, or transaction taxes that are payable as a
                                result of the supply of goods and/or services by Zak Electric to the customer shall be
                                the responsibility of and be paid by the customer to Zak Electric on the same terms and
                                conditions as any other amounts due on any invoice by Zak Electric to the customer.</p>
                        </Rule>
                    </div>
                    <div className='right-side-wrapper'>
                        <Rule>
                            <h1>DELIVERY</h1>
                            <p>Delivery shall be considered to be made by Zak Electric when goods are delivered to the
                                customer or at the customer’s designated destination. Delivery of services shall be
                                considered to be made where services are performed upon or at the lands or premises
                                designated by the customer for the same. The risk of loss of the goods shall be the
                                customer’s upon and after delivery to the customer. Risk of loss in any services
                                performed shall be the customer’s upon and after the provision of such services or
                                partial services as may have been performed to the date of such loss.</p>
                        </Rule>
                        <Rule>
                            <h1>RECOVERY</h1>
                            <p>Customer agrees that until goods and services supplied by Zak Electric are paid in full,
                                Zak Electric may enter upon or into any lands and premises where the goods and services
                                sold are installed or otherwise located for the purposes of removing the same without
                                any recourse from the customer or any third party. The customer agrees to indemnify and
                                save harmless Zak Electric from any suit, action, demand, or other claim whatsoever or
                                howsoever made by any third party(ies) or whomever arising out of any such recovery
                                action taken by Zak Electric in this regard. Any action taken by Zak Electric in
                                accordance with this paragraph shall be without prejudice to any other right or remedy
                                in law or equity or otherwise that Zak Electric may have to recover all amounts owing by
                                the customer to Zak Electric.</p>
                        </Rule>
                        <Rule>
                            <h1>RECOVERY COSTS</h1>
                            <p>All costs for the recovery of any goods supplied, services performed, or the invoiced
                                amount
                                thereof shall be collectible by Zak Electric in addition to any other amounts
                                outstanding to
                                it from the customer. Such amounts shall include, but not be limited to, legal fees for
                                collecting any amounts outstanding. The customer shall pay Zak Electric’s costs in this
                                regard on a solicitor and his client basis.
                            </p>
                        </Rule>
                        <Rule>
                            <h1>ENTIRE AGREEMENT</h1>
                            <p>
                                The foregoing provisions and the provisions on the attached side form the agreement
                                between
                                the parties. All terms and conditions on the attached which are expressly contrary to
                                the
                                terms and conditions expressed above shall be deemed to amend the terms and conditions
                                expressed above accordingly.
                            </p>
                        </Rule>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default TermsAndConditions;