import React from "react";
import "./HomePageCarts.scss";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const HomePageCarts = ({ data }) => {
  return (
    <div className="cart">
      <div className="image-part">
        <motion.img
          initial={{ y: 150 }}
          whileInView={{ y: 0 }}
          transition={{ ease: "easeOut", duration: 0.5 }}
          viewport={{ once: true, amount: 0.8 }}
          src={data.photo}
          alt={data.name}
        />
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.4, delay: 0.4 }}
          viewport={{ once: true, amount: 0.8 }}
          className="red-circle">
          {data.svg}
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, amount: 0.8 }}
        transition={{ ease: "easeOut", duration: 0.4, delay: 0.7 }}
        className="bottom-part">
        <div className="top-part">
          <h4>{data.name}</h4>
          <p>{data.text}</p>
        </div>
        <div className="features-boxes">
          {data.features.map((feature, index) => (
            <div key={index} className="box">
              <p>{feature}</p>
            </div>
          ))}
        </div>
        <Link className="white-border-button" to={data.link}>
          Read More
        </Link>
      </motion.div>
    </div>
  );
};

export default HomePageCarts;
