import React from 'react';
import BlackBanner from "../../components/BlackBanner";
import {Container} from "@mui/material";
import Rule from "../../components/Rule";

const LegalDisclaimer = () => {
    const data = {
        title: "Legal Disclaimer",
        description: "Clarifying Our Legal Position"
    }
    return (
        <div className={'privacy-policy-wrapper'}>
            <BlackBanner data={data}/>
            <Container>
                <div className='rules-wrapper'>
                    <div className='left-side-wrapper'>
                        <Rule>
                            <p>Any information obtained on this website is for informational purposes only and is not,
                                nor is it intended to be, a substitute for professional or electrical advice. Always
                                consult a licensed electrician before taking any action on your own.</p>
                        </Rule>
                        <Rule>
                            <p>The material provided here does not constitute specific professional or electrical advice
                                to the reader. Merely contacting us does not establish a professional relationship.
                                Please refrain from sending any confidential information to us until we have been
                                formally engaged. We cannot guarantee the confidentiality of any email transmissions
                                sent to us.</p>
                        </Rule>
                        <Rule>
                            <p>You should only act or refrain from acting based on any information on this website after
                                seeking advice from a licensed electrician.
                                Furthermore, there is no assurance that the information presented on this website is
                                applicable to your specific situation, jurisdiction, or local laws.</p>
                        </Rule>
                    </div>
                    <div className='right-side-wrapper'>
                        <Rule>
                            <p>
                                While we make reasonable efforts to ensure the accuracy of the content posted on this
                                website,
                                we do not guarantee its accuracy or completeness. The material is intended for general
                                informational purposes only and is not comprehensive or intended to serve as the sole
                                basis
                                for
                                any decisions. We may include links to other websites, and while we strive to provide
                                reliable
                                resources, we do not endorse the content of linked websites unless specifically stated
                                otherwise.
                            </p>
                        </Rule>
                        <Rule>
                            <p>
                                All content on this site, including images, drawings, logos, video clips, written
                                material,
                                and other content, is subject to copyright, trademark, and/or intellectual property
                                rights
                                owned and managed by Zak Electric.
                            </p>
                        </Rule>
                        <Rule>
                            <p>
                                We disclaim all liability with respect to any loss or damage that may arise from the use
                                or
                                reliance upon any material or information presented on our website.
                            </p>
                        </Rule>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default LegalDisclaimer;