import React, { useEffect, useState } from "react";
import s from "./HeaderSecondPart.module.scss";
import menu from "../../../../assets/menu.svg";
import vector from "../../../../assets/vector.svg";
import { Container, Drawer, IconButton, Popper } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const HeaderSecondPart = ({ handleOpenConsultation }) => {
  const navigate = useNavigate();
  const navigations = [
    { name: "Home", link: "/" },
    { name: "About", link: "/about" },
    { name: "Services" },
    { name: "Our Projects", link: "/portfolio" },
    { name: "Contact Us", link: "/contact" },
  ];
  const [selected, setSelected] = useState(navigations[0].name);
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, name) => {
    if (name === "Services") setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  useEffect(() => {
    const closePopperOnClickOutside = (event) => {
      if (
        anchorEl &&
        event.target.innerText !== "Residential" &&
        event.target.innerText !== "Commercial" &&
        event.target.innerText !== "Industrial"
      ) {
        setAnchorEl(null);
      }
    };

    document.addEventListener("mousedown", closePopperOnClickOutside);

    return () => {
      document.removeEventListener("mousedown", closePopperOnClickOutside);
    };
  }, [anchorEl]);

  const mobileNavigation = [
    {
      name: "Quick Access",
      links: [
        { name: "Home", navigation: "/" },
        { name: "About", navigation: "/about" },
        // { name: "Our Projects", navigation: "/portfolio" },
        { name: "Contact us", navigation: "/contact" },
      ],
    },
    {
      name: "Our Projects",
      links: [
        { name: "Services", navigation: "/portfolio?type=Services" },
        { name: "Residential", navigation: "/portfolio?type=Residential" },
        { name: "Commercial", navigation: "/portfolio?type=Commercial" },
        { name: "EV Chargers", navigation: "/portfolio?type=EV Charger" },
        {
          name: "Security Systems",
          navigation: "/portfolio?type=Security Systems",
        },
      ],
    },
    {
      name: "Services",
      links: [
        { name: "Residential", navigation: "/services/residential" },
        { name: "Commercial", navigation: "/services/commercial" },
        { name: "Industrial", navigation: "/services/industrial" },
      ],
    },
    {
      name: "Legal Document",
      links: [
        { name: "Legal Disclaimer", navigation: "/legal-disclaimer" },
        { name: "Terms & Conditions", navigation: "/terms-conditions" },
        { name: "Privacy Policy", navigation: "/privacy-policy" },
      ],
    },
  ];

  const handleMobileNavigation = (link) => {
    setOpenDrawer(false);
    navigate(link);
  };

  const [anchorElProjects, setAnchorElProjects] = useState(null);

  const handleProjectsClick = (event, name) => {
    if (name === "Our Projects")
      setAnchorElProjects(anchorElProjects ? null : event.currentTarget);
  };

  const openProjects = Boolean(anchorElProjects);
  const idProjects = openProjects ? "projects-popper" : undefined;

  useEffect(() => {
    const closeProjectsPopperOnClickOutside = (event) => {
      if (
        anchorElProjects &&
        ![
          "Services",
          "Residential",
          "Commercial",
          "EV Chargers",
          "Security Systems",
        ].includes(event.target.innerText)
      ) {
        setAnchorElProjects(null);
      }
    };

    document.addEventListener("mousedown", closeProjectsPopperOnClickOutside);

    return () => {
      document.removeEventListener(
        "mousedown",
        closeProjectsPopperOnClickOutside
      );
    };
  }, [anchorElProjects]);

  return (
    <>
      <Container
        sx={{
          background: {
            xs: "black",
            lg: "linear-gradient(to left, #FFFFFF 50%, #000000 50%) !important",
          },
        }}>
        <div
          style={{
            height: "88px",
            width: "100%",
            display: "flex",
            maxWidth: "1512px",
          }}>
          <div className={s.box1}>
            <img
              onClick={() => navigate("/")}
              src={vector}
              className={s.logo}
              alt="img"
            />
            <IconButton onClick={() => toggleDrawer()}>
              <img src={menu} className={s.menu} alt="img" />
            </IconButton>
            <Drawer
              sx={{
                zIndex: 3,
                ".MuiDrawer-paper": {
                  width: "100%",
                  backgroundColor: "black",
                  overflowY: "auto",
                  zIndex: 3,
                },
              }}
              anchor="right"
              open={openDrawer}
              onClose={closeDrawer}>
              <div className={s.insideDrawer}>
                {mobileNavigation.map((navigation) => (
                  <div className={s.insideBox}>
                    <h3>{navigation.name.toUpperCase()}</h3>
                    {navigation.links.map((link) => (
                      <button
                        onClick={() => handleMobileNavigation(link.navigation)}>
                        <p>{link.name}</p>
                      </button>
                    ))}
                  </div>
                ))}
              </div>
            </Drawer>
          </div>
          <div className={s.box3}>
            <div className={s.box2}>
              {navigations.map((navigation, index) => (
                <Link
                  key={index}
                  aria-describedby={
                    navigation.name === "Services" ? id : idProjects
                  }
                  onClick={(event) => {
                    setSelected(navigation.name);
                    if (navigation.name === "Services") {
                      handleClick(event, navigation.name);
                    } else if (navigation.name === "Our Projects") {
                      handleProjectsClick(event, navigation.name);
                    }
                  }}
                  onMouseEnter={(event) => {
                    if (navigation.name === "Services") {
                      handleClick(event, navigation.name);
                    } else if (navigation.name === "Our Projects") {
                      handleProjectsClick(event, navigation.name);
                    }
                  }}
                  to={
                    navigation.name !== "Services" &&
                    navigation.name !== "Our Projects" &&
                    `${navigation.link}`
                  }
                  className={navigation.name === selected ? s.text2 : s.text1}>
                  {navigation.name}
                  {navigation.name === "Our Projects" && (
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4.5 6L8.5 10L12.5 6"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  {navigation.name === "Services" && (
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4.5 6L8.5 10L12.5 6"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </Link>
              ))}
            </div>
            <Popper
              id={id}
              style={{ zIndex: "99" }}
              open={open}
              anchorEl={anchorEl}>
              <div className={s.insidePopper}>
                <IconButton
                  onClick={() => navigate("/services/residential")}
                  id={id}
                  className={s.row}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none">
                    <path
                      d="M14.7502 7.76319L9.31594 2.32898L2.3291 9.31582H3.88173V14.75C3.88173 15.1618 4.04531 15.5567 4.33649 15.8479C4.62766 16.1391 5.02258 16.3027 5.43436 16.3027H11.2567M6.987 16.3027V11.6448C6.987 11.233 7.15058 10.8381 7.44175 10.5469C7.73293 10.2557 8.12784 10.0921 8.53963 10.0921H10.0923C10.6054 10.0921 11.0611 10.3406 11.3437 10.7248M14.7502 10.8685L13.1975 13.9737H16.3028L14.7502 17.079"
                      stroke="#EC1C23"
                      strokeWidth="1.16447"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p>Residential</p>
                </IconButton>
                <IconButton
                  onClick={() => navigate("/services/commercial")}
                  id={id}
                  className={s.row}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none">
                    <path
                      d="M2.3291 16.9343H10.0923V6.06583M3.88173 16.9343V6.06583L10.0923 2.96057V6.06583M6.987 7.61847V7.62623M6.987 9.94741V9.95518M6.987 12.2764V12.2841M6.987 14.6053V14.6131M10.0923 6.06583L13.3916 8.58886"
                      stroke="#EC1C23"
                      strokeWidth="1.16447"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.3622 10.7238L12.8096 13.829H15.9148L14.3622 16.9343"
                      stroke="#EC1C23"
                      strokeWidth="1.16447"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p>Commercial</p>
                </IconButton>
                <IconButton
                  onClick={() => navigate("/services/industrial")}
                  id={id}
                  className={s.row}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none">
                    <path
                      d="M6.21063 16.5658H16.3027M6.21063 10.3553H7.76326V7.25006L11.6448 10.3553H14.7501M14.7501 10.3553V16.5658M14.7501 10.3553L13.6353 2.92287C13.6215 2.83084 13.5752 2.74682 13.5046 2.68611C13.4341 2.62541 13.3441 2.59207 13.251 2.59216H12.3622C12.2701 2.59202 12.1811 2.62457 12.1108 2.68401C12.0406 2.74344 11.9937 2.8259 11.9787 2.91666L10.8685 9.57901M6.98695 13.4606H7.76326M10.8685 13.4606H11.6448"
                      stroke="#EC1C23"
                      strokeWidth="1.16447"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.10508 10.3553L4.65771 13.4606H1.55245L3.10508 16.5659"
                      stroke="#EC1C23"
                      strokeWidth="1.16447"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p>Industrial</p>
                </IconButton>
              </div>
            </Popper>
            <Popper
              id={idProjects}
              style={{ zIndex: "99" }}
              open={openProjects}
              anchorEl={anchorElProjects}>
              <div className={s.insidePopper}>
                <IconButton
                  onClick={() => navigate("/portfolio?type=Services")}
                  id={idProjects}
                  className={s.row}>
                  <p>Services</p>
                </IconButton>
                <IconButton
                  onClick={() => navigate("/portfolio?type=Residential")}
                  id={idProjects}
                  className={s.row}>
                  <p>Residential</p>
                </IconButton>
                <IconButton
                  onClick={() => navigate("/portfolio?type=Commercial")}
                  id={idProjects}
                  className={s.row}>
                  <p>Commercial</p>
                </IconButton>
                <IconButton
                  onClick={() => navigate("/portfolio?type=EV Charger")}
                  id={idProjects}
                  className={s.row}>
                  <p>EV Chargers</p>
                </IconButton>
                <IconButton
                  onClick={() => navigate("/portfolio?type=Security Systems")}
                  id={idProjects}
                  className={s.row}>
                  <p>Security Systems</p>
                </IconButton>
              </div>
            </Popper>
            <button
              onClick={handleOpenConsultation}
              className="red-button"
              style={{ padding: "12px 24px" }}>
              Get a consultant
            </button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default HeaderSecondPart;
