import { useState } from "react";
import "./Banner.scss";
import NavigationButtons from "../NavigationButtons";
import useSwipe from "../../hooks/useSwipe";
import { motion, AnimatePresence } from "framer-motion";
import img1 from "../../assets/1.webp";
import img2 from "../../assets/2.png";
import img3 from "../../assets/3.png";
import img4 from "../../assets/4.png";
import img5 from "../../assets/lastslide.jpg";
import { useMediaQuery } from "@mui/material";

const Banner = ({ handleOpenConsultation }) => {
  const mobile = useMediaQuery("(min-width: 1200px)");

  const slides = [
    {
      header: "Trusted Local Electricians Serving Kitchener-Waterloo",
      text: "Fast, expert electrical services for homes, businesses, and industries.",
      image: img1,
    },
    {
      header:
        "Electrify Your Journey with Our EV Charger Station Installation Services.",
      text: "Quick, Skillful, and Reliable – Get a Seamless, High-Quality Charging Solution Today.",
      image: img2,
    },
    {
      header: "Crafting Commercial Environments for Your Business Success.",
      text: "Our Expertise Lies in Optimizing Electrical Layouts for Maximum Productivity.",
      image: img3,
    },
    {
      header: "Elevate Your Space with Stunning Spotlights.",
      text: "Our Team Excels at Installing Lighting to Transform Your Environment indoor and outdoor.",
      image: img4,
    },
    {
      header:
        "Transforming Basements Rough Ins into Dream Homes with Professional Expertise.",
      image: img5,
    },
  ];
  const [slide, setSlide] = useState(0);
  const handleSwipeLeft = () => {
    setSlide((prevState) => (prevState + 1) % slides.length);
  };

  const handleSwipeRight = () => {
    setSlide((prevState) => (prevState - 1 + slides.length) % slides.length);
  };

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => handleSwipeLeft(),
    onSwipedRight: () => handleSwipeRight(),
  });

  return (
    <div className="banner-wrapper">
      <div className="inside-wrapper">
        <div className="left-side">
          <div className="top-part">
            <div className="text-part">
              <h1>
                ZAK<b className="red">ELECTRIC</b>
              </h1>
              <h3>{slides[slide].header}</h3>
              <p>{slides[slide]?.text}</p>
              <button
                style={{ marginTop: "auto" }}
                className="red-button"
                onClick={() => handleOpenConsultation()}>
                Get a Consultation
              </button>
            </div>
          </div>
          <div className="bottom-part">
            <NavigationButtons
              setSlide={setSlide}
              count={slides.length}
              slide={slide}
            />
            <div className="logos">
              <img src={require("../../assets/image 6.png")} alt="logo" />
              <img
                src={require("../../assets/homebannerlogo.png")}
                alt="logo"
              />
            </div>
          </div>
        </div>
        <div className="right-side" {...swipeHandlers}>
          <div className="dots-wrapper">
            {slides.map((dot, index) => (
              <div
                key={index}
                className={`dot ${index !== slide && "not-selected"}`}></div>
            ))}
          </div>
          <AnimatePresence mode="wait">
            <motion.img
              key={slide}
              initial={{ opacity: 0, x: mobile ? 50 : 0 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: mobile ? 50 : 0 }}
              transition={{ ease: "easeOut", duration: 0.3 }}
              src={slides[slide].image}
              alt="right"
            />
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Banner;
