import React from "react";
import "./ExcellentService.scss";
import { useMediaQuery } from "@mui/material";
import HomePageCarts from "./HomePageCarts";
import ZackSwiper from "../ZackSwiper";
import { SwiperSlide } from "swiper/react";
import SwiperNavigationButtons from "../SwiperNavigationButtons";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import sv1 from "../../assets/sv-1.svg";

const ExcellentService = () => {
  const matches1 = useMediaQuery("(max-width: 600px)");
  const matches2 = useMediaQuery("(min-width: 1200px)");
  const carts = [
    {
      name: "Residential",
      photo: require("../../assets/brick-house.jpg"),
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none">
          <path
            d="M38 20L24 6L6 24H10V38C10 39.0609 10.4214 40.0783 11.1716 40.8284C11.9217 41.5786 12.9391 42 14 42H29M18 42V30C18 28.9391 18.4214 27.9217 19.1716 27.1716C19.9217 26.4214 20.9391 26 22 26H26C27.322 26 28.496 26.64 29.224 27.63M38 28L34 36H42L38 44"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      text: "Your trusted electricians for all your home electrical needs. From installations to repairs, We've got you covered.",
      features: [
        "House Wiring",
        "Installation",
        "Service",
        "Upgrades",
        "Smart Home",
        "Rough-Ins",
        "Emergency Lighting",
        "Panel Upgrades",
      ],
      link: "/services/residential",
    },
    {
      name: "Commercial",
      photo: require("../../assets/office.png"),
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none">
          <path
            d="M6 42H26V14M10 42V14L26 6V14M18 18V18.02M18 24V24.02M18 30V30.02M18 36V36.02M26 14L34.5 20.5"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M37 26L33 34H41L37 42"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      text: "Running a business is challenging. Leave your electrical concerns to our experienced professionals so you can focus on what truly matters – your success.",
      features: [
        "Lighting Upgrades",
        "Emergency Lighting",
        "Low Voltage Switchings",
        "120v/347 Volt Lighting",
        "Rough-Ins",
        "Panel Upgrades",
      ],
      link: "/services/commercial",
    },
    {
      name: "Industrial",
      photo: require("../../assets/factory.jpg"),
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none">
          <path
            d="M32 42H6M32 26H28V18L18 26H10M10 26V42M10 26L12.872 6.852C12.9075 6.6149 13.027 6.39843 13.2087 6.24204C13.3904 6.08566 13.6223 5.99976 13.862 6H16.152C16.3891 5.99964 16.6185 6.08349 16.7995 6.23661C16.9804 6.38973 17.1011 6.60216 17.14 6.836L20 24M30 34H28M20 34H18"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40 26L36 34H44L40 42"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      text: "Count on our licensed electricians for on-site industrial electrical services prioritizing safety and efficiency. We bring knowledge and experience to your facility, ensuring smooth operations.",
      features: [
        "Safety Devices",
        "Smoke Detectors",
        "Exit / Emergency Signs",
        "Exterior Lighting",
      ],
      link: "/services/industrial",
    },
  ];

  const portfolios = [
    {
      name: "Beautiful Sunroom",
      image: require("../../assets/brick-house.jpg"),
    },
    {
      name: "Electrical Panel Upgrades",
      image: require("../../assets/electrical-work.png"),
    },
    {
      name: "Basement Wiring",
      image: require("../../assets/electrical-work-2.png"),
    },
    { name: "Bathroom Electrical", image: require("../../assets/w.jpg") },
    {
      name: "Pot Light Installation",
      image: sv1,
    },
  ];
  return (
    <div
      style={{
        background: "var(--Black-50, #F6F6F6)",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <div className="excellent-service-wrapper">
        <div className="top-part">
          <div className="red-alert">
            <p>ESA # 7015394</p>
          </div>
          <div className="top-part-header">
            <p>OUR SERVICES</p>
            <h2>Delivering Excellent Services Through Our Expert Team</h2>
          </div>
          <p>
            At our core, excellence defines us. Our exceptionally skilled and
            professional team is dedicated to providing top-quality services
            that stand out. Each team member brings a wealth of expertise to
            every project, ensuring your complete satisfaction.
          </p>
        </div>
        <div className="cart-wrapper">
          {carts.map((cart, index) => (
            <HomePageCarts key={index} data={cart} />
          ))}
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true, amount: 0.8 }}
          transition={{ ease: "easeOut", duration: 0.4 }}
          className="portfolio-wrapper">
          <h2>Portfolio</h2>
          <ZackSwiper
            Looping={true}
            slidesPerView={matches1 ? 2.3 : matches2 ? 4 : 3}
            navButtons={
              <div className="nav-buttons">
                <SwiperNavigationButtons />
                <Link className="white-border-button" to="/portfolio">
                  See All
                </Link>
              </div>
            }
            spaceBetween={matches1 ? 10 : 30}>
            {portfolios.map((portfolio, index) => (
              <SwiperSlide key={index}>
                <img src={portfolio.image} alt={portfolio.name} />
                <p>{portfolio.name}</p>
              </SwiperSlide>
            ))}
          </ZackSwiper>
        </motion.div>
      </div>
    </div>
  );
};

export default ExcellentService;
