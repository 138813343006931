const NavigationButtons = ({ slide, count, setSlide }) => {
  const onSlideChange = (position) => {
    if (position === "forward") {
      setSlide((prevState) => (prevState + 1) % count);
    }
    if (position === "backward") {
      setSlide((prevState) => (prevState - 1 + count) % count);
    }
  };

  return (
    <div className="navigation-part">
      <div className="navigation-buttons">
        <button onClick={() => onSlideChange("backward")}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_475_36624)">
              <path d="M6.73745 4.46245C6.93745 4.63745 7.03745 4.84995 7.03745 5.09995C7.03745 5.34995 6.93745 5.54995 6.73745 5.69995L3.21245 9.29995H18.4375C18.9875 9.34995 19.2875 9.64995 19.3375 10.2C19.2875 10.75 18.9875 11.05 18.4375 11.1H3.21245L6.77495 14.6625C7.12495 15.0875 7.12495 15.5125 6.77495 15.9375C6.34995 16.2875 5.92495 16.2875 5.49995 15.9375L0.399951 10.8375C0.0499512 10.4125 0.0499512 9.98745 0.399951 9.56245L5.49995 4.46245C5.92495 4.11245 6.33745 4.11245 6.73745 4.46245Z" />
            </g>
            <defs>
              <clipPath id="clip0_475_36624">
                <rect
                  width="19.2"
                  height="19.2"
                  fill="white"
                  transform="matrix(1 0 0 -1 0.399902 19.8)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
        <button onClick={() => onSlideChange("forward")}>
          <svg
            width="20"
            height="13"
            viewBox="0 0 20 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2625 0.462573C13.0625 0.637573 12.9625 0.850073 12.9625 1.10007C12.9625 1.35007 13.0625 1.55007 13.2625 1.70007L16.7875 5.30007H1.56255C1.01255 5.35007 0.712547 5.65007 0.662548 6.20007C0.712547 6.75007 1.01255 7.05007 1.56255 7.10007H16.7875L13.225 10.6626C12.875 11.0876 12.875 11.5126 13.225 11.9376C13.65 12.2876 14.075 12.2876 14.5 11.9376L19.6 6.83757C19.95 6.41257 19.95 5.98757 19.6 5.56257L14.5 0.462573C14.075 0.112573 13.6625 0.112573 13.2625 0.462573Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default NavigationButtons;
