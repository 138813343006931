import React, { useState, useEffect } from "react";
import { FormControlLabel, Modal, Radio, RadioGroup } from "@mui/material";
import s from "./ConsultationModal.module.scss";
import { emailjsSend } from "../emailjsConfig";
import ReCAPTCHA from "react-google-recaptcha";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import Thankssvg from "../assets/Thankssvg.svg";

const ConsultationModal = ({ open, handleClose }) => {
  const [value, setValue] = useState("female");
  const [disabled, setDisabled] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const checkIsValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  // const [showRecapcha, setShowRecapcha] = useState(false);
  const [error, setError] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [recaptchaStatus, setReCapchaStatus] = useState(false);
  const [consult, setconsult] = useState({});

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if (name === "radio_buttons_group") {
      setValue(event.target.value);
    }
    setconsult({ ...consult, [name]: value });
    if (name === "email") {
      if (value.match(checkIsValidEmail)) {
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSending(true);
    const res = await emailjsSend(consult, "template_8zhh6tf");
    setIsSending(false);
    if (res) {
      setError(false);
      setSuccess(true);
    } else {
      setError(true);
    }
  };

  // const handleRecapcha = async (token) => {
  //   if (token) {
  //     try {
  //       const res = await publicRequest.post("/verifyReCapcha", {
  //         secret: process.env.REACT_APP_SECRET_KEY,
  //         response: token,
  //       });
  //       const status = res.data.success;
  //       if (status) {
  //         setReCapchaStatus(true);
  //       } else {
  //         setReCapchaStatus(false);
  //       }
  //       setError(false);
  //     } catch (err) {
  //       setError(true);
  //     }
  //   } else {
  //     setReCapchaStatus(false);
  //   }
  // };

  useEffect(() => {
    const validate = () => {
      if (
        consult.full_name &&
        consult.business_name &&
        consult.phone &&
        consult.email &&
        consult.Address &&
        consult.date &&
        consult.time &&
        consult.radio_buttons_group &&
        consult.message &&
        // (!showRecapcha || (showRecapcha && recaptchaStatus)) &&
        isValidEmail
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    validate();
  }, [consult, isValidEmail]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className={s.insideModal}>
        <div className={s.leftSide}>
          <button className={s.whiteButton} onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="27"
              viewBox="0 0 26 27"
              fill="none">
              <path
                d="M19.2004 7.1001L6.40039 19.9001M6.40039 7.1001L19.2004 19.9001"
                stroke="white"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Close
          </button>
          <h1>Let’s Schedule Your Consultation</h1>
          <p>
            Ready to Illuminate Your Space? Fill out the form to book your
            consultation and get started on brightening up your place.
          </p>
        </div>
        {success ? (
          <div className={s.thanks}>
            <img src={Thankssvg} alt="Thank_you" />
            <h3>Thank you for submitting</h3>
            <p>Your message has been sent!</p>
          </div>
        ) : isSending ? (
          <div className={s.Submitting}>
            <CircularProgress style={{ width: "50px", height: "50px" }} />
            Submitting...
          </div>
        ) : (
          <div className={s.rightSide}>
            <div className={s.rowInputs}>
              <div className={s.input}>
                <p>Full Name</p>
                <input
                  placeholder={"Enter your full name"}
                  type="text"
                  name="full_name"
                  id="full_name"
                  onChange={handleFormChange}
                />
              </div>
              <div className={s.input}>
                <p>Business Name</p>
                <input
                  placeholder={"Enter your business name"}
                  type="text"
                  name="business_name"
                  id="business_name"
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className={s.rowInputs}>
              <div className={s.input}>
                <p>E-mail Address</p>
                <input
                  placeholder={"Enter your e-mail address"}
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleFormChange}
                />
              </div>
              <div className={s.input}>
                <p>Phone Number</p>
                <input
                  placeholder={"Enter your phone number"}
                  type="text"
                  name="phone"
                  id="phone"
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className={s.rowInputs}>
              <div className={s.input}>
                <p>Address</p>
                <input
                  placeholder={
                    "Example: 123 Main Street, Apt 4B, Los Angeles, California, 90001, United States."
                  }
                  type="text"
                  name="Address"
                  id="Address"
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className={s.radioGroup}>
              <p>Service Type</p>
              <RadioGroup
                className={s.groupWrapper}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio_buttons_group"
                value={value}
                // onChange={handleChange}
                onChange={handleFormChange}>
                <FormControlLabel
                  className={value === "Residential" ? s.radio1 : s.radio2}
                  value="Residential"
                  control={<Radio />}
                  label="Residential"
                />
                <FormControlLabel
                  className={value === "Commercial" ? s.radio1 : s.radio2}
                  value="Commercial"
                  control={<Radio />}
                  label="Commercial"
                />
                <FormControlLabel
                  className={value === "Industrial" ? s.radio1 : s.radio2}
                  value="Industrial"
                  control={<Radio />}
                  label="Industrial"
                />
              </RadioGroup>
            </div>
            <div className={s.rowInputs}>
              <div className={s.input}>
                <p>Preferred Dates & Times</p>
                <div className={s.selectWrapper}>
                  <input
                    onFocus={(e) => (e.target.type = "date")}
                    type="text"
                    placeholder={"Select Date"}
                    name="date"
                    id="date"
                    onChange={handleFormChange}
                  />
                  <input
                    onFocus={(e) => (e.target.type = "time")}
                    type="text"
                    placeholder={"Select Time"}
                    name="time"
                    id="time"
                    onChange={handleFormChange}
                  />
                </div>
              </div>
            </div>
            <div className={s.rowInputs}>
              <div className={s.input}>
                <p>Message</p>
                <textarea
                  rows={5}
                  placeholder={"Please provide details about service request"}
                  name="message"
                  id="message"
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <button
              className={s.redButton}
              type="submit"
              disabled={disabled || isSending}
              onClick={handleSubmit}>
              Submit
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ConsultationModal;
