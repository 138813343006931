import React from 'react';
import './BlackBanner.scss'

const BlackBanner = ({data}) => {
    return (
        <div className='black-banner-wrapper'>
            <h1>{data.title}</h1>
            <p>{data.description}</p>
        </div>
    );
};

export default BlackBanner;