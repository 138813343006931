import "./ClientSay.scss";
import ZackSwiper from "./ZackSwiper";
import { SwiperSlide } from "swiper/react";
import { Rating, useMediaQuery } from "@mui/material";
import SwiperNavigationButtons from "./SwiperNavigationButtons";

const ClientSay = () => {
  const reviews = [
    {
      name: "Will Fast",
      rating: 5,
      type: "EV charger installation",
      positive: "Professionalism, Punctuality, Quality, Responsiveness, Value",
      text: "Excellent experience with these guys! They showed up on time, did the work quickly, and even installed my new car charger on the wall (I assumed they’d just install the outlet but this was above and beyond what I asked for). When I decided I didn’t like the type of light they installed in my backyard, they had one of their guys come back the very next morning and change out the light no problem. Super professional and very good communication, which is something I really appreciate. Very reasonable pricing. I will definitely use ZAK again. Highly recommended.",
    },
    {
      name: "Lucas",
      rating: 4.5,
      type: "EV charger installation",
      positive: "Professionalism, Punctuality, Quality, Responsiveness, Value",
      text:
        "It has been an absolute delight conducting business with Zak Electric, From the very first meeting, we felt like a\n" +
        "priority and were given a comprehensive and transparent look into their workflow, they also helped us choose the\n" +
        "best solutions for our needs.\n" +
        "Zak has earned our highest recommendation due to their commitment to excellence, professional conduct, and\n" +
        "unparalleled customer service. If you are in need of reliable and first-rate electrical work, don't hesitate to contact\n" +
        "these guys, with them—you won't be disappointed.",
    },
    {
      name: "Nimroj Maknojiya",
      rating: 4.5,
      type: "EV charger installation",
      positive: "Professionalism, Punctuality, Quality, Responsiveness, Value",
      text:
        "It has been an absolute delight conducting business with Zak Electric, From the very first meeting, we felt like a\n" +
        "priority and were given a comprehensive and transparent look into their workflow, they also helped us choose the\n" +
        "best solutions for our needs.\n" +
        "Zak has earned our highest recommendation due to their commitment to excellence, professional conduct, and\n" +
        "unparalleled customer service. If you are in need of reliable and first-rate electrical work, don't hesitate to contact\n" +
        "these guys, with them—you won't be disappointed.",
    },
    {
      name: "Shanavas MA",
      rating: 5,
      type: "outlet and switch installation",
      text: "I recently hired Zak as an electrician, and I couldn't be happier with his service. He completed the electrical work for our entire basement and placed pot lights surrounding the exterior of our house. Zak is highly skilled and completed the job efficiently and effectively. He was punctual, professional, and his attention to detail was impressive. I felt confident in his expertise, and he went above and beyond to ensure customer satisfaction. I highly recommend Zak for any electrical needs",
    },
    {
      name: "Dipali Sharma",
      rating: 4.5,
      type: "Pot light installation",
      text: "We recently hired ZAK electric to add potlights and replace our ceiling fans. Amgad and his team were very professional and did an exceptional job. Amgad was very honest during all our conversations. He made suggestions on what would work best for our project. Definitely will be hiring them again for any electrical work in the future.",
    },
    {
      name: "Ahmed M",
      rating: 5,
      type: "Full electrical installation",
      positive: "Professionalism",
      text:
        "Amgad and and his team did an amazing job building a strong electrical foundation for my house. Him and his team worked tirelessly to undo all the bad wiring in my house, install a new 200 amp panel, an EV charger, pot lights, and installed new outlets and wiring for my entire basement. They also installed wired smoke detectors throughout the entire house. Their work was professional and they went above and beyond to ensure my wife, daughter, and I had power running when the snow storm hit (they stayed very late).\n" +
        " It’s hard to find a good contractor, I’ve been cheated many times, but I can tell you for sure that Amgad and his company are definitely the ones I’ll be referring over and over again. Thank you again!",
    },
    {
      name: "Olivia Escobar",
      rating: 5,
      type: "Electrical outlet and switch relocation",
      positive: "Professionalism, Punctuality, Quality, Responsiveness, Value",
      text:
        "Amazing job this guys did very happy on the service and was done in 10 minutes. Definitely recommend this guys\n" +
        "for your electrical needs.",
    },
  ];
  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}>
      <div className="client-say-wrapper">
        <div className="top-header">
          <p>testimonials</p>
          <h2>What Client Say’s</h2>
        </div>
        <ZackSwiper
          slidesPerView={!matches ? 1.5 : 1.05}
          centered={!matches}
          spaceBetween={!matches ? 30 : 10}
          navButtons={
            <div style={{ marginTop: "20px" }}>
              <SwiperNavigationButtons red={true} />
            </div>
          }>
          {reviews.map((review, index) => (
            <SwiperSlide key={index}>
              <div className="inside-swiper">
                <div className="top-part">
                  <h2>{review.name}</h2>
                  <Rating name="read-only" value={review.rating} readOnly />
                </div>
                <h3>{review.type}</h3>
                {review.positive && (
                  <div className="positive-part">
                    <p className="bold">Positive:</p>
                    <p>{review.positive}</p>
                  </div>
                )}

                <p className="text">{review.text}</p>
              </div>
            </SwiperSlide>
          ))}
        </ZackSwiper>
      </div>
    </div>
  );
};

export default ClientSay;
