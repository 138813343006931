// Import Swiper React components
import { Swiper } from "swiper/react";
import "./ZackSwiper.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const ZackSwiper = ({
  children,
  navButtons,
  slidesPerView,
  spaceBetween = 30,
  centered,
  Looping,
}) => {
  return (
    <Swiper
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      className="mySwiper"
      centeredSlides={centered}
      loop={Looping}>
      {navButtons}
      {/*You can style your own swiper-slide on the parent component where this Swiper is called by just using the .swiper-slide css className*/}
      {children}
    </Swiper>
  );
};

export default ZackSwiper;
