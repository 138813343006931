import React from "react";
import "./Home.scss";
import NewBanner from "../../components/HomePageComponents/Banner";
import ElevatingResidential from "../../components/HomePageComponents/ElevatingResidential";
import ExcellentService from "../../components/HomePageComponents/ExcellentService";
import OurServing from "../../components/ContactUs/OurServing/OurServing";
import WhyChooseUs from "../../components/HomePageComponents/WhyChooseUs";
import ClientSay from "../../components/ClientSay";
import { InView } from "react-intersection-observer";
import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet";

const domain = "https://zakelectric.ca";

const Home = ({ handleOpenConsultation }) => {
  const matche = useMediaQuery("(min-width: 1166px)");
  const [isInView, setIsInView] = React.useState([]);
  function handleInView(SectionName, InView) {
    if (InView) {
      setIsInView((isInView) => [...isInView, SectionName]);
    }
  }

  return (
    <div className="home-page-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Zak Electric - Residential Commercial & Industrial Electrical Services
        </title>
        <meta
          name="description"
          content="Your trusted local electrician in Kitchener and Waterloo. Fast, reliable, and professional residential, commercial, and industrial services."
        />
        <link rel="canonical" href={`${domain}/`} />{" "}
      </Helmet>
      <NewBanner handleOpenConsultation={handleOpenConsultation} />
      {matche ? (
        <ElevatingResidential />
      ) : (
        <>
          <InView
            onChange={(inView) => handleInView("About", inView)}
            triggerOnce>
            {" "}
          </InView>
          {isInView.includes("About") && <ElevatingResidential />}
        </>
      )}
      <InView
        onChange={(inView) => handleInView("Contact", inView)}
        triggerOnce>
        {" "}
      </InView>
      {isInView.includes("Contact") && <ExcellentService />}
      {isInView.includes("Contact") && (
        <WhyChooseUs handleOpenConsultation={handleOpenConsultation} />
      )}
      {isInView.includes("Contact") && <ClientSay />}
      {isInView.includes("Contact") && (
        <OurServing handleOpenConsultation={handleOpenConsultation} />
      )}
    </div>
  );
};

export default Home;
